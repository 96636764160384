/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { copySurveyUser, CopySurveyUserParams } from '../fn/survey-user/copy-survey-user';

@Injectable({ providedIn: 'root' })
export class SurveyUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `copySurveyUser()` */
  static readonly CopySurveyUserPath = '/surveyUser/copy/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copySurveyUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  copySurveyUser$Response(params: CopySurveyUserParams, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return copySurveyUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copySurveyUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  copySurveyUser(params: CopySurveyUserParams, context?: HttpContext): Observable<void> {
    return this.copySurveyUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
