import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeInfoListComponent } from './codeinfo/code-info-list/code-info-list.component';
import { EditCompanyComponent } from './common/edit-company/edit-company.component';
import { EditUserComponent } from './common/edit-user/edit-user.component';
import { Constants } from './constants/constants';
import { DeleteTeamSessionComponent } from './design/delete-team-session/delete-team-session.component';
import { MigrateTeamComponent } from './design/migrate-teams/migrate-team.component';
import { CognitiveAssessmentResetComponent } from './hire/cognitive-assessment-reset/cognitive-assessment-reset.component';
import { EditBehavioralAssessmentComponent } from './inspire/edit-behavioral-assessment/edit-behavioral-assessment.component';
import { AnonymizationCardComponent } from './plg/health-check/anonymization-card/anonymization-card.component';
// eslint-disable-next-line max-len
import { HealthCheckContainerComponent } from './plg/health-check/health-check-container/health-check-container.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import {
    Permissions,
    PermissionsService
} from './shared/services/permissions.service';
import { UiAutomationSelfServeCardComponent } from './ui-automation-self-serve-card/ui-automation-self-serve-card.component';
import { UseropsMovePersonComponent } from './userops/move-person/move-person.component';
import { RestoreArchivedAssessmentsComponent } from './userops/restore-archived-assessments/restore-archived-assessments.component';
import { CopyJobComponent } from './hire/copy-job/copy-job.component';
import { CopySurveyuserComponent } from './userops/copy-surveyuser/copy-surveyuser.component';

/* Remember to go update sidebar.component.ts too! */
const routes: Routes = [
    {
        path: 'plg',
        children: [
            {
                path: 'healthcheck',
                component: HealthCheckContainerComponent,
                canActivate: [PermissionsService],
                data: {
                    permission: Permissions.Healthcheck
                }
            },
            {
                path: 'anonymization',
                component: AnonymizationCardComponent,
                canActivate: [PermissionsService],
                data: {
                    permission: Permissions.EngineerWrite,
                    environmentRestriction: [Constants.Environment.Development]
                }
            },
            {
                path: 'codeinfo',
                component: CodeInfoListComponent,
                canActivate: [PermissionsService],
                data: {
                    permission: Permissions.EngineerWrite,
                    environmentRestriction: [Constants.Environment.Development]
                }
            }
        ]
    },
    {
        path: 'sdet',
        children: [
            {
                path: 'selfserve',
                component: UiAutomationSelfServeCardComponent,
                canActivate: [PermissionsService],
                data: {
                    permission: Permissions.EngineerWrite,
                    environmentRestriction: [Constants.Environment.Development]
                }
            }
        ]
    },
    {
        path: 'userops',
        canActivateChild: [PermissionsService],
        data: { permission: Permissions.AnyRead },
        children: [
            {
                path: 'common',
                children: [
                    {
                        path: 'users',
                        children: [
                            { path: '', component: EditUserComponent },
                            { path: ':id', component: EditUserComponent }
                        ]
                    },
                    {
                        path: 'companies',
                        children: [
                            { path: '', component: EditCompanyComponent },
                            { path: ':id', component: EditCompanyComponent }
                        ]
                    }
                ]
            },
            {
                path: 'inspire',
                children: [
                    {
                        path: 'behavioralassessments',
                        children: [
                            {
                                path: '',
                                component: EditBehavioralAssessmentComponent
                            },
                            {
                                path: ':id',
                                component: EditBehavioralAssessmentComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: 'hire',
                children: [
                    {
                        path: 'cognitiveassessments/reset',
                        component: CognitiveAssessmentResetComponent
                    },
                    {
                        path: 'job/copy',
                        component: CopyJobComponent
                    }
                ]
            },
            {
                path: 'design',
                children: [
                    {
                        path: 'deleteteamsession',
                        component: DeleteTeamSessionComponent,
                        canActivate: [PermissionsService],
                        data: { permission: Permissions.UserOpsWrite }
                    },
                    {
                        path: 'migrateteam',
                        component: MigrateTeamComponent,
                        canActivate: [PermissionsService],
                        data: { permission: Permissions.UserOpsWrite }
                    }
                ]
            },
            {
                path: 'storedprocedures',
                children: [
                    {
                        path: 'moveperson',
                        component: UseropsMovePersonComponent,
                        canActivate: [PermissionsService],
                        data: { permission: Permissions.UserOpsWrite }
                    },
                    {
                        path: 'restorearchivedassessments',
                        component: RestoreArchivedAssessmentsComponent,
                        canActivate: [PermissionsService],
                        data: { permission: Permissions.UserOpsWrite }
                    },
                    {
                        path: 'copysurveyuser',
                        component: CopySurveyuserComponent,
                        canActivate: [PermissionsService],
                        data: { permission: Permissions.UserOpsWrite }
                    }
                ]
            }
        ]
    },

    { path: '**', component: DashboardComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
