/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { copyPositionToNewCompanyAsync$Json } from '../fn/job/copy-position-to-new-company-async-json';
import { CopyPositionToNewCompanyAsync$Json$Params } from '../fn/job/copy-position-to-new-company-async-json';
import { copyPositionToNewCompanyAsync$Plain } from '../fn/job/copy-position-to-new-company-async-plain';
import { CopyPositionToNewCompanyAsync$Plain$Params } from '../fn/job/copy-position-to-new-company-async-plain';

@Injectable({ providedIn: 'root' })
export class JobService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `copyPositionToNewCompanyAsync()` */
  static readonly CopyPositionToNewCompanyAsyncPath = '/job/copyPositionToNewCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyPositionToNewCompanyAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyPositionToNewCompanyAsync$Plain$Response(params?: CopyPositionToNewCompanyAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return copyPositionToNewCompanyAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyPositionToNewCompanyAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyPositionToNewCompanyAsync$Plain(params?: CopyPositionToNewCompanyAsync$Plain$Params, context?: HttpContext): Observable<string> {
    return this.copyPositionToNewCompanyAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyPositionToNewCompanyAsync$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyPositionToNewCompanyAsync$Json$Response(params?: CopyPositionToNewCompanyAsync$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return copyPositionToNewCompanyAsync$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `copyPositionToNewCompanyAsync$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  copyPositionToNewCompanyAsync$Json(params?: CopyPositionToNewCompanyAsync$Json$Params, context?: HttpContext): Observable<string> {
    return this.copyPositionToNewCompanyAsync$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
