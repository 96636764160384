/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PermissionsModuleAccessLevel {
  Unknown = 'Unknown',
  NoAccess = 'NoAccess',
  LimitedAccess = 'LimitedAccess',
  FullAccess = 'FullAccess',
  AllModuleAdmin = 'AllModuleAdmin',
  AllCompanyAdmin = 'AllCompanyAdmin'
}
