/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTeams$Json } from '../fn/design-team/get-teams-json';
import { GetTeams$Json$Params } from '../fn/design-team/get-teams-json';
import { getTeams$Plain } from '../fn/design-team/get-teams-plain';
import { GetTeams$Plain$Params } from '../fn/design-team/get-teams-plain';
import { migrateTeams$Json } from '../fn/design-team/migrate-teams-json';
import { MigrateTeams$Json$Params } from '../fn/design-team/migrate-teams-json';
import { migrateTeams$Plain } from '../fn/design-team/migrate-teams-plain';
import { MigrateTeams$Plain$Params } from '../fn/design-team/migrate-teams-plain';
import { MigrateTeamsResponseCto } from '../models/migrate-teams-response-cto';
import { UserCompanyMyGroupCto } from '../models/user-company-my-group-cto';

@Injectable({ providedIn: 'root' })
export class DesignTeamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTeams()` */
  static readonly GetTeamsPath = '/design/team';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeams$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Plain$Response(params?: GetTeams$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserCompanyMyGroupCto>>> {
    return getTeams$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeams$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Plain(params?: GetTeams$Plain$Params, context?: HttpContext): Observable<Array<UserCompanyMyGroupCto>> {
    return this.getTeams$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserCompanyMyGroupCto>>): Array<UserCompanyMyGroupCto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeams$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Json$Response(params?: GetTeams$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserCompanyMyGroupCto>>> {
    return getTeams$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeams$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeams$Json(params?: GetTeams$Json$Params, context?: HttpContext): Observable<Array<UserCompanyMyGroupCto>> {
    return this.getTeams$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserCompanyMyGroupCto>>): Array<UserCompanyMyGroupCto> => r.body)
    );
  }

  /** Path part for operation `migrateTeams()` */
  static readonly MigrateTeamsPath = '/design/team/migrate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `migrateTeams$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  migrateTeams$Plain$Response(params?: MigrateTeams$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MigrateTeamsResponseCto>> {
    return migrateTeams$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `migrateTeams$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  migrateTeams$Plain(params?: MigrateTeams$Plain$Params, context?: HttpContext): Observable<MigrateTeamsResponseCto> {
    return this.migrateTeams$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MigrateTeamsResponseCto>): MigrateTeamsResponseCto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `migrateTeams$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  migrateTeams$Json$Response(params?: MigrateTeams$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MigrateTeamsResponseCto>> {
    return migrateTeams$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `migrateTeams$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  migrateTeams$Json(params?: MigrateTeams$Json$Params, context?: HttpContext): Observable<MigrateTeamsResponseCto> {
    return this.migrateTeams$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MigrateTeamsResponseCto>): MigrateTeamsResponseCto => r.body)
    );
  }

}
