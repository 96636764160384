/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum SoftwareModule {
  Unknown = 'Unknown',
  Hire = 'Hire',
  Design = 'Design',
  Diagnose = 'Diagnose',
  Inspire = 'Inspire',
  Perform = 'Perform'
}
