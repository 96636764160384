import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/constants/constants';
import { ConfigurationService } from '../services/configuration.service';
import {
    Permissions,
    PermissionsService
} from '../services/permissions.service';

interface Link {
    icon: string;
    text: string;
    route: string;
    permission: string | null;
    enabled: boolean;
}

interface LinkGroup {
    name: string;
    links: Link[];
    enabled: boolean;
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    private allowedLinksSubject$ = new Subject<LinkGroup[]>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public allowedLinks = this.allowedLinksSubject$.asObservable();

    /* Links should be defined here.  What links are shown will be evaluated based on the User's Permissions. */
    /* Remember to go update app-routing.module.ts too! */
    /* Find More Icons here: https://fonts.google.com/icons?icon.set=Material+Icons */

    private linkgroups: LinkGroup[] = [
        {
            name: '',
            enabled: true,
            links: [
                {
                    icon: 'home',
                    route: '/',
                    text: 'Home',
                    permission: null,
                    enabled: true
                }
            ]
        },
        {
            name: 'PLG',
            enabled: true,
            links: [
                {
                    icon: 'person_add',
                    route: '/plg/healthcheck',
                    text: 'Healthcheck',
                    permission: Permissions.Healthcheck,
                    enabled: true // all environments
                },
                {
                    icon: 'group_remove',
                    route: '/plg/anonymization',
                    text: 'Anonymization',
                    permission: Permissions.EngineerWrite,
                    enabled: this.isDevelopment
                },
                {
                    icon: 'terminal',
                    route: '/plg/codeinfo',
                    text: 'Code Info Services',
                    permission: Permissions.EngineerRead,
                    enabled: this.isDevelopment
                }
            ]
        },
        {
            name: 'SDET',
            enabled: this.isDevelopment,
            links: [
                {
                    icon: 'bug_report',
                    route: '/sdet/selfserve',
                    text: 'Self Serve UI Automation',
                    permission: Permissions.EngineerWrite,
                    enabled: this.isDevelopment
                }
            ]
        },
        {
            name: 'Common',
            enabled: true,
            links: [
                {
                    icon: 'person',
                    route: '/userops/common/users',
                    text: 'Users',
                    permission: Permissions.AnyRead,
                    enabled: true
                },
                {
                    icon: 'apartment',
                    route: '/userops/common/companies',
                    text: 'Companies',
                    permission: Permissions.AnyRead,
                    enabled: true
                }
            ]
        },
        {
            name: 'Inspire',
            enabled: true,
            links: [
                {
                    icon: 'assignment',
                    route: '/userops/inspire/behavioralassessments',
                    text: 'Behavioral Assessments',
                    permission: Permissions.AnyRead,
                    enabled: true
                }
            ]
        },
        {
            name: 'Hire',
            enabled: true,
            links: [
                {
                    icon: 'settings',
                    route: '/userops/hire/cognitiveassessments/reset',
                    text: 'Reset Cognitive Assessments',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                },
                {
                    icon: 'settings',
                    route: '/userops/hire/job/copy',
                    text: 'Copy Job',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                }
            ]
        },
        {
            name: 'Design',
            enabled: true,
            links: [
                {
                    icon: 'group',
                    route: '/userops/design/deleteteamsession',
                    text: 'Delete Team Session(s)',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                },
                {
                    icon: 'group',
                    route: '/userops/design/migrateteam',
                    text: 'Migrate Team(s)',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                }
            ]
        },
        {
            name: 'UserOps',
            enabled: true,
            links: [
                {
                    icon: '',
                    route: '/userops/storedprocedures/moveperson',
                    text: 'Move Person',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                },
                {
                    icon: '',
                    route: '/userops/storedprocedures/restorearchivedassessments',
                    text: 'Restore archived assessments',
                    permission: Permissions.UserOpsWrite,
                    enabled: true
                }
                // Disabling this till we solve the edge cases Dheeraj: 01/09/2025
                // {
                //     icon: '',
                //     route: '/userops/storedprocedures/copysurveyuser',
                //     text: 'Copy Survey User',
                //     permission: Permissions.UserOpsWrite,
                //     enabled: true
                // }
            ]
        }
    ];

    constructor(
        private readonly router: Router,
        permissionsService: PermissionsService,
        private readonly configurationService: ConfigurationService
    ) {
        permissionsService.permissionsChanged.subscribe(() => {
            const tempLinkGroups = new Array<LinkGroup>();
            this.linkgroups
                .filter((x) => x.enabled)
                .forEach((group) => {
                    const tempLinks = new Array<Link>();
                    group.links
                        .filter((y) => y.enabled)
                        .forEach((link) => {
                            if (
                                permissionsService.hasPermission(
                                    link.permission
                                )
                            ) {
                                tempLinks.push(link);
                            }
                        });

                    if (tempLinks.length > 0) {
                        tempLinkGroups.push({
                            name: group.name,
                            links: tempLinks,
                            enabled: true
                        });
                    }
                });
            this.allowedLinksSubject$.next(tempLinkGroups);
        });
    }

    get isDevelopment(): boolean {
        return (
            this.configurationService.configuration.environmentName ===
            Constants.Environment.Development
        );
    }

    public goto(link: Link): void {
        this.router.navigateByUrl(link.route);
    }
}
