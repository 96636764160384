/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { BehavioralAssessmentService } from './services/behavioral-assessment.service';
import { CircleService } from './services/circle.service';
import { CognitiveAssessmentService } from './services/cognitive-assessment.service';
import { CompanyService } from './services/company.service';
import { DesignTeamService } from './services/design-team.service';
import { DesignTeamSessionService } from './services/design-team-session.service';
import { FolderService } from './services/folder.service';
import { FrontEndSettingsService } from './services/front-end-settings.service';
import { JobService } from './services/job.service';
import { OkService } from './services/ok.service';
import { SurveyUserService } from './services/survey-user.service';
import { UserService } from './services/user.service';
import { UserOpsService } from './services/user-ops.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BehavioralAssessmentService,
    CircleService,
    CognitiveAssessmentService,
    CompanyService,
    DesignTeamService,
    DesignTeamSessionService,
    FolderService,
    FrontEndSettingsService,
    JobService,
    OkService,
    SurveyUserService,
    UserService,
    UserOpsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
