<mat-card appearance="outlined" class="copy-surveyuser-card">
    <mat-card-header>
        <mat-card-title>Copy Survey User</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="copySurveyUserFom"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-error
                *ngIf="
                    copySurveyUserFom.errors?.['companyIdRequired'] &&
                    copySurveyUserFom.touched &&
                    copySurveyUserFom.dirty
                "
            >
                Company Id is required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Company Id</mat-label>
                        <input matInput formControlName="companyId" />
                    </mat-form-field>
                    <app-company-search-input
                        selectionType="long"
                        (userSelected)="setTargetCompanyId($event)"
                    />
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="submit()">
            Submit
        </button>
    </mat-card-actions>

    <div style="margin-top: 5px">
        <mat-spinner *ngIf="loading"></mat-spinner>

        <form
            [formGroup]="movePersonResponseForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <textarea
                placeholder="Success!"
                formControlName="responseOkMessage"
                *ngIf="!loading && wasResponseSuccessful === true"
                class="text-area"
            >
            </textarea>

            <textarea
                placeholder="An error occurred."
                formControlName="responseErrorMessage"
                *ngIf="!loading && wasResponseSuccessful === false"
                class="text-area"
            >
            </textarea>
        </form>
    </div>
</mat-card>
