<mat-card appearance="outlined" class="restore-assessments-card">
    <mat-card-header>
        <mat-card-title>Restore Archived Assessments</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form
            [formGroup]="restoreArchivedAssessmentsForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <mat-error
                *ngIf="
                    restoreArchivedAssessmentsForm.errors?.[
                        'folderIdRequired'
                    ] &&
                    (restoreArchivedAssessmentsForm.touched ||
                        restoreArchivedAssessmentsForm.dirty)
                "
            >
                Folder ID is required.
            </mat-error>
            <mat-grid-list cols="3" rowHeight="75px" gutterSize="5px">
                <mat-grid-tile>
                    <mat-form-field appearance="fill">
                        <mat-label>Folder ID</mat-label>
                        <input matInput formControlName="folderId" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="submit()">
            Submit
        </button>
        <button mat-raised-button (click)="reset()">Reset</button>
    </mat-card-actions>

    <div style="margin-top: 5px">
        <mat-spinner *ngIf="loading"></mat-spinner>

        <form
            [formGroup]="movePersonResponseForm"
            novalidate
            (keyup)="handleKeyPress($event)"
        >
            <textarea
                placeholder="Success!"
                formControlName="responseOkMessage"
                *ngIf="!loading && wasResponseSuccessful === true"
                class="text-area"
            >
            </textarea>

            <textarea
                placeholder="An error occurred."
                formControlName="responseErrorMessage"
                *ngIf="!loading && wasResponseSuccessful === false"
                class="text-area"
            >
            </textarea>
        </form>
    </div>
</mat-card>

<div class="results-table" *ngIf="searchResults.length > 0">
    <table
        mat-table
        [dataSource]="searchResults"
        class="mat-elevation-z8"
        style="width: 100%"
    >
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.firstName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.lastName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.surveyUser.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element">
                {{ element.company.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="bsId">
            <th mat-header-cell *matHeaderCellDef>BSID</th>
            <td mat-cell *matCellDef="let element">
                {{ element.bsid }}
            </td>
        </ng-container>

        <ng-container matColumnDef="assessmentGuid">
            <th mat-header-cell *matHeaderCellDef>
                Behavioral Assessment Guid
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.assessmentGuid }}
            </td>
        </ng-container>

        <ng-container matColumnDef="restore">
            <th mat-header-cell *matHeaderCellDef>Restore</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.restore"></mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <button
        mat-raised-button
        color="primary"
        class="save-button"
        (click)="saveChanges()"
    >
        Save
    </button>
</div>

<div class="successful-table-container">
    <mat-spinner *ngIf="saving"></mat-spinner>
    <div *ngIf="restoreCompleted">
        <div class="successful-table-heading">
            Failed Restored SurveyUserGuids: {{ failedToRestoreList.length }}
        </div>
        <table
            mat-table
            [dataSource]="failedToRestoreList"
            class="mat-elevation-z8 successful-table"
            *ngIf="failedToRestoreList.length > 0"
            style="width: 100%"
        >
            <ng-container matColumnDef="surveyUserGuid">
                <th mat-header-cell *matHeaderCellDef>SurveyUserGuid</th>
                <td mat-cell *matCellDef="let element">
                    {{ element }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="restoreTableDisplayedColumns"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: restoreTableDisplayedColumns"
            ></tr>
        </table>
    </div>
</div>
