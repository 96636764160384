/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BehavioralAssessmentCto } from '../../models/behavioral-assessment-cto';

export interface GetBehavioralAssessments$Plain$Params {
  Email?: string;
  FirstName?: string;
  LastName?: string;
  BSID?: string;
  CompanyGuid?: string;
  SessionGuid?: string;
}

export function getBehavioralAssessments$Plain(http: HttpClient, rootUrl: string, params?: GetBehavioralAssessments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BehavioralAssessmentCto>>> {
  const rb = new RequestBuilder(rootUrl, getBehavioralAssessments$Plain.PATH, 'get');
  if (params) {
    rb.query('Email', params.Email, {});
    rb.query('FirstName', params.FirstName, {});
    rb.query('LastName', params.LastName, {});
    rb.query('BSID', params.BSID, {});
    rb.query('CompanyGuid', params.CompanyGuid, {});
    rb.query('SessionGuid', params.SessionGuid, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<BehavioralAssessmentCto>>;
    })
  );
}

getBehavioralAssessments$Plain.PATH = '/behavioralAssessment/search';
