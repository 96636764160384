import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, takeUntil } from 'rxjs';
import { CognitiveAccessLevel } from '../../api/models/cognitive-access-level';
import { CompanyUserModuleAccessPermissionsCto } from '../../api/models/company-user-module-access-permissions-cto';
import { ModuleUpdateCto } from '../../api/models/module-update-cto';
import { PermissionsModuleAccessLevel } from '../../api/models/permissions-module-access-level';
import { SoftwareModule } from '../../api/models/software-module';
import { UserUpdateRequestCto } from '../../api/models/user-update-request-cto';
import { CompanyService } from '../../api/services/company.service';
import { UserService } from '../../api/services/user.service';
import {
    Permissions,
    PermissionsService
} from '../../shared/services/permissions.service';

interface ModuleValues {
    accessLevel: PermissionsModuleAccessLevel;
    vmAccess: boolean;
    isCogSender?: boolean;
}

interface ModulePermissions {
    design: ModuleValues;
    diagnose: ModuleValues;
    hire: ModuleValues;
    inspire: ModuleValues;
    perform: ModuleValues;
}

interface GlobalPermissions {
    isCogAdmin: boolean;
    isOrgAdmin: boolean;
}

interface AccessLevelOption {
    name: string;
    value: PermissionsModuleAccessLevel;
    displayModules: (keyof ModulePermissions)[];
}

type EditPermissionsFormValues = GlobalPermissions & ModulePermissions;

@Component({
    selector: 'app-edit-user-permissions-dialog',
    templateUrl: './edit-user-permissions-dialog.component.html',
    styleUrls: ['./edit-user-permissions-dialog.component.scss']
})
export class EditUserPermissionsDialogComponent implements OnInit, OnDestroy {
    editPermissionsForm = this.fb.group({
        isOrgAdmin: false,
        isCogAdmin: false,
        design: this.fb.group({
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        }),
        diagnose: this.fb.group({
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        }),
        hire: this.fb.group({
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false,
            isCogSender: false
        }),
        inspire: this.fb.group({
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        }),
        perform: this.fb.group({
          accessLevel: PermissionsModuleAccessLevel.Unknown,
          vmAccess: false
        })
    });

    organizationName = '';
    pendingChanges = false;
    saving = false;

    fullAccessLevel = PermissionsModuleAccessLevel.FullAccess;
    accessLevelOptions: AccessLevelOption[] = [
        {
            name: 'Unknown',
            value: PermissionsModuleAccessLevel.Unknown,
            displayModules: []
        },
        {
            name: 'No Access',
            value: PermissionsModuleAccessLevel.NoAccess,
            displayModules: []
        },
        {
            name: 'Limited Access',
            value: PermissionsModuleAccessLevel.LimitedAccess,
            displayModules: []
        },
        {
            name: 'Full Access',
            value: PermissionsModuleAccessLevel.FullAccess,
            displayModules: []
        },
        {
            name: 'Admin',
            value: PermissionsModuleAccessLevel.AllModuleAdmin,
            displayModules: []
        }
    ];

    canEdit = false;
    // for cog access and org access controls
    isHireFree = false;

    modulePaid: Record<keyof ModulePermissions, boolean> = {
        design: false,
        diagnose: false,
        hire: false,
        inspire: false,
        perform: false
    };

    loading = true;

    private initialGlobalPermissions: GlobalPermissions = {
        isOrgAdmin: false,
        isCogAdmin: false
    };

    private initialModulePermissions: ModulePermissions = {
        design: {
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        },
        diagnose: {
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        },
        hire: {
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false,
            isCogSender: false
        },
        inspire: {
            accessLevel: PermissionsModuleAccessLevel.Unknown,
            vmAccess: false
        },
        perform: {
          accessLevel: PermissionsModuleAccessLevel.Unknown,
          vmAccess: false
        }
    };

    private destroyed$ = new Subject<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<EditUserPermissionsDialogComponent>,
        private readonly user: UserService,
        private readonly company: CompanyService,
        private readonly permissions: PermissionsService,
        private readonly snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            userGuid: string;
            organization: {
                name: string;
                id: string;
            };
            permissions: CompanyUserModuleAccessPermissionsCto;
        }
    ) {}

    ngOnInit(): void {
        this.canEdit = this.permissions.hasPermission(Permissions.UserOpsWrite);
        this.setModuleAccountTypes();
        this.toggleFormViewOnly();
        this.initializeFormWatchers();
        this.setOrganizationName();
        this.setModulePermissions();
        this.setGlobalOrgSettings();
        this.initializeGlobalPermissionValues();
        this.initializeModulePermissionValues();
        this.initializeAccessLevelDisplayOptions();
        this.updateAccessLevelDisplayOptions();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    saveChanges(): void {
        if (!this.pendingChanges) return;

        const request = this.buildRequest();

        this.saving = true;
        this.user
            .updateUserModuleAccess$Json({
                userId: this.data.userGuid,
                body: request
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.saving = false;
                if (value.errorResponse) {
                    this.snackbar.open('An error occurred.', 'Dismiss');
                    return;
                }

                this.snackbar.open(
                    `Permissions updated for ${this.data.organization.name}.`,
                    'Dismiss',
                    {
                        duration: 3000
                    }
                );
                this.dialogRef.close(request);
            });
    }

    private setOrganizationName(): void {
        if (!this.data.organization.name) return;
        this.organizationName = this.data.organization.name;
    }

    private setModulePermissions(): void {
        if (!this.data.permissions.accessLevel) return;
        if (!this.data.permissions.vmAccess) return;

        this.initialModulePermissions.design.accessLevel =
            this.consolidateAdminAccessLevel(
                this.data.permissions.accessLevel['Design']
            );
        this.initialModulePermissions.diagnose.accessLevel =
            this.consolidateAdminAccessLevel(
                this.data.permissions.accessLevel['Diagnose']
            );
        this.initialModulePermissions.hire.accessLevel =
            this.consolidateAdminAccessLevel(
                this.data.permissions.accessLevel['Hire']
            );
        this.initialModulePermissions.inspire.accessLevel =
            this.consolidateAdminAccessLevel(
                this.data.permissions.accessLevel['Inspire']
            );
        this.initialModulePermissions.perform.accessLevel =
            this.consolidateAdminAccessLevel(
              this.data.permissions.accessLevel['Perform']
            );

        this.initialModulePermissions.design.vmAccess =
            this.data.permissions.vmAccess['Design'] ?? false;
        this.initialModulePermissions.diagnose.vmAccess =
            this.data.permissions.vmAccess['Diagnose'] ?? false;
        this.initialModulePermissions.hire.vmAccess =
            this.data.permissions.vmAccess['Hire'] ?? false;
        this.initialModulePermissions.inspire.vmAccess =
            this.data.permissions.vmAccess['Inspire'] ?? false;
        this.initialModulePermissions.perform.vmAccess =
          this.data.permissions.vmAccess['Perform'] ?? false;

        this.initialModulePermissions.hire.isCogSender =
            this.data.permissions.cognitiveAccessLevel ===
                CognitiveAccessLevel.Send ||
            this.data.permissions.cognitiveAccessLevel ===
                CognitiveAccessLevel.Admin;
    }

    private setGlobalOrgSettings(): void {
        this.initialGlobalPermissions.isOrgAdmin =
            this.data.permissions.isCompanyAdmin ?? false;
        this.initialGlobalPermissions.isCogAdmin =
            this.data.permissions.cognitiveAccessLevel === 'Admin';
    }

    private initializeGlobalPermissionValues(): void {
        this.editPermissionsForm.patchValue(this.initialGlobalPermissions);
    }

    private initializeModulePermissionValues(): void {
        this.editPermissionsForm.patchValue(this.initialModulePermissions);
    }

    private initializeFormWatchers(): void {
        this.editPermissionsForm.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.pendingChanges = this.hasPendingChanges();
            });

        this.editPermissionsForm.controls.isOrgAdmin.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateModuleState(value ?? false);
            });

        this.editPermissionsForm.controls.isCogAdmin.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateCogSender(value ?? false);
            });

        this.editPermissionsForm
            .get(['design', 'accessLevel'])
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateVmAccessControl('design', value);
                this.disableVmAccessControl('design', value);
            });

        this.editPermissionsForm
            .get(['diagnose', 'accessLevel'])
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateVmAccessControl('diagnose', value);
                this.disableVmAccessControl('diagnose', value);
            });

        this.editPermissionsForm
            .get(['hire', 'accessLevel'])
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateVmAccessControl('hire', value);
                this.disableVmAccessControl('hire', value);
            });

        this.editPermissionsForm
            .get(['inspire', 'accessLevel'])
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.updateVmAccessControl('inspire', value);
                this.disableVmAccessControl('inspire', value);
            });

        this.editPermissionsForm
            .get(['perform', 'accessLevel'])
            ?.valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
              this.updateVmAccessControl('perform', value);
              this.disableVmAccessControl('perform', value);
            });
    }

    private hasPendingChanges(): boolean {
        const values = this.editPermissionsForm.getRawValue();
        return (
            this.initialGlobalPermissions.isOrgAdmin !== values.isOrgAdmin ||
            this.initialGlobalPermissions.isCogAdmin !== values.isCogAdmin ||
            this.initialModulePermissions.design.accessLevel !==
                values.design.accessLevel ||
            this.initialModulePermissions.design.vmAccess !==
                values.design.vmAccess ||
            this.initialModulePermissions.diagnose.accessLevel !==
                values.diagnose.accessLevel ||
            this.initialModulePermissions.diagnose.vmAccess !==
                values.diagnose.vmAccess ||
            this.initialModulePermissions.hire.accessLevel !==
                values.hire.accessLevel ||
            this.initialModulePermissions.hire.vmAccess !==
                values.hire.vmAccess ||
            this.initialModulePermissions.hire.isCogSender !==
                values.hire.isCogSender ||
            this.initialModulePermissions.inspire.accessLevel !==
                values.inspire.accessLevel ||
            this.initialModulePermissions.inspire.vmAccess !==
                values.inspire.vmAccess ||
            this.initialModulePermissions.perform.accessLevel !==
            values.perform.accessLevel ||
            this.initialModulePermissions.perform.vmAccess !==
            values.perform.vmAccess
        );
    }

    private setOrgAdmin(): void {
        const hasCogAccessControl = this.editPermissionsForm.controls.hire.get('isCogSender');
        const orgAdminSettings: ModulePermissions = {
            design: {
                accessLevel: PermissionsModuleAccessLevel.AllModuleAdmin,
                vmAccess: true
            },
            diagnose: {
                accessLevel: PermissionsModuleAccessLevel.AllModuleAdmin,
                vmAccess: true
            },
            hire: {
                accessLevel: PermissionsModuleAccessLevel.AllModuleAdmin,
                vmAccess: true,
                isCogSender: hasCogAccessControl?.value ?? false
            },
            inspire: {
                accessLevel: PermissionsModuleAccessLevel.AllModuleAdmin,
                vmAccess: true
            },
            perform: {
              accessLevel: PermissionsModuleAccessLevel.AllModuleAdmin,
              vmAccess: true
            }
        };

        this.editPermissionsForm.patchValue(orgAdminSettings);
    }

    private disableVmAccessControl(
        module: keyof ModulePermissions,
        accessLevel: PermissionsModuleAccessLevel
    ): void {
        const disabledVmAccess = [
            PermissionsModuleAccessLevel.Unknown,
            PermissionsModuleAccessLevel.NoAccess,
            PermissionsModuleAccessLevel.AllModuleAdmin,
            PermissionsModuleAccessLevel.AllCompanyAdmin
        ];

        if (disabledVmAccess.includes(accessLevel)) {
            this.editPermissionsForm
                .get([module, 'vmAccess'])
                ?.disable({ emitEvent: false });
        } else {
            this.editPermissionsForm
                .get([module, 'vmAccess'])
                ?.enable({ emitEvent: false });
        }
    }

    private updateVmAccessControl(
        module: keyof ModulePermissions,
        accessLevel: PermissionsModuleAccessLevel
    ): void {
        if (
            accessLevel === PermissionsModuleAccessLevel.Unknown ||
            accessLevel === PermissionsModuleAccessLevel.NoAccess
        ) {
            this.editPermissionsForm.get([module, 'vmAccess'])?.setValue(false);
        }

        if (
            accessLevel === PermissionsModuleAccessLevel.AllModuleAdmin ||
            accessLevel === PermissionsModuleAccessLevel.AllCompanyAdmin
        ) {
            this.editPermissionsForm.get([module, 'vmAccess'])?.setValue(true);
        }
    }

    private toggleFormViewOnly(): void {
        if (!this.canEdit) {
            this.editPermissionsForm.disable({ emitEvent: false });
        } else {
            this.editPermissionsForm.enable({ emitEvent: false });
        }
    }

    private consolidateAdminAccessLevel(
        accessLevel: PermissionsModuleAccessLevel
    ): PermissionsModuleAccessLevel {
        return accessLevel === PermissionsModuleAccessLevel.AllCompanyAdmin
            ? PermissionsModuleAccessLevel.AllModuleAdmin
            : accessLevel;
    }

    private updateAccessLevelDisplayOptions(): void {
        const unknownOption = this.accessLevelOptions.find(
            (a) => a.value === PermissionsModuleAccessLevel.Unknown
        );
        const fullAccessOption = this.accessLevelOptions.find(
            (a) => a.value === PermissionsModuleAccessLevel.FullAccess
        );

        // Removes unknown level if a module is already active.
        for (const key of Object.keys(this.initialModulePermissions)) {
            if (
                unknownOption &&
                this.initialModulePermissions[key as keyof ModulePermissions]
                    .accessLevel !== PermissionsModuleAccessLevel.Unknown
            ) {
                unknownOption.displayModules =
                    unknownOption?.displayModules.filter((m) => m !== key) ??
                    unknownOption.displayModules;
            }
        }

        // Removes full access level from hire, perform and inspire
        if (fullAccessOption) {
            fullAccessOption.displayModules =
                fullAccessOption?.displayModules.filter(
                    (module) => module !== 'hire' && module !== 'inspire' && module !== 'perform'
                ) ?? fullAccessOption.displayModules;
        }
    }

    private updateCogSender(isCogAdmin: boolean): void {
        const hireCogSenderControl = this.editPermissionsForm.get([
            'hire',
            'isCogSender'
        ]);

        if (isCogAdmin) {
            hireCogSenderControl?.setValue(true);
        }
    }

    private initializeAccessLevelDisplayOptions(): void {
        for (const option of this.accessLevelOptions) {
            option.displayModules = ['design', 'diagnose', 'hire', 'inspire', 'perform'];
        }
    }

    private updateModuleState(isOrgAdmin: boolean): void {
        if (isOrgAdmin) {
            this.setOrgAdmin();
            for (const key of Object.keys(this.initialModulePermissions)) {
                this.editPermissionsForm
                    .get([key, 'accessLevel'])
                    ?.disable({ emitEvent: false });
            }
        } else {
            const isCogSender = this.editPermissionsForm.get(['hire', 'isCogSender'])?.value 
                ?? false;
            this.initializeModulePermissionValues();
            for (const key of Object.keys(this.initialModulePermissions)) {
                this.editPermissionsForm
                    .get([key, 'accessLevel'])
                    ?.enable({ emitEvent: false });
            }
            this.editPermissionsForm
                .get(['hire', 'isCogSender'])
                ?.setValue(isCogSender);
        }
    }

    private setModuleAccountTypes(): void {
        this.loading = true;
        this.company
            .getCompany$Json({
                companyId: this.data.organization.id
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
                this.loading = false;

                this.modulePaid.design =
                    value.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
                        ?.find((m) => m.name === 'Design2')
                        ?.accountType?.toLowerCase() === 'paid';
                this.modulePaid.diagnose =
                    value.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
                        ?.find((m) => m.name === 'Diagnose2')
                        ?.accountType?.toLowerCase() === 'paid';
                this.modulePaid.hire =
                    value.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
                        ?.find((m) => m.name === 'HireHero')
                        ?.accountType?.toLowerCase() === 'paid';
                this.modulePaid.inspire =
                    value.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
                        ?.find((m) => m.name === 'Inspire2')
                        ?.accountType?.toLowerCase() === 'paid';
                this.modulePaid.perform =
                  value.softwareModuleAccessConfiguration?.softwareModuleAccessConfig
                    ?.find((m) => m.name === 'Perform')
                    ?.accountType?.toLowerCase() === 'paid';

                if (!this.modulePaid.hire) {
                    this.editPermissionsForm.controls.isCogAdmin.disable({
                        emitEvent: false
                    });
                    this.isHireFree = true;
                    this.editPermissionsForm
                        .get(['hire', 'isCogSender'])
                        ?.disable({ emitEvent: false });
                }
            });
    }

    private buildRequest(): UserUpdateRequestCto {
      const values = this.editPermissionsForm.getRawValue() as EditPermissionsFormValues;
      const initialValues: EditPermissionsFormValues = {
        ...this.initialGlobalPermissions,
        ...this.initialModulePermissions
      };

      const moduleNames: (keyof ModulePermissions)[] = ['design', 'diagnose', 'hire', 'inspire', 'perform'];
      const moduleNameToSoftwareModule: Record<keyof ModulePermissions, SoftwareModule> = {
        design: SoftwareModule.Design,
        diagnose: SoftwareModule.Diagnose,
        hire: SoftwareModule.Hire,
        inspire: SoftwareModule.Inspire,
        perform: SoftwareModule.Perform
      };

      const moduleUpdates: ModuleUpdateCto[] = moduleNames
        .filter(moduleName => this.moduleValuesChanged(
          initialValues.isOrgAdmin,
          values.isOrgAdmin,
          initialValues[moduleName],
          values[moduleName]
        ))
        .map(moduleName => ({
          accessLevel: values[moduleName].accessLevel,
          canSpendVMs: values[moduleName].vmAccess,
          module: moduleNameToSoftwareModule[moduleName]
        }));

      return {
        companyId: this.data.organization.id,
        isCogSender: values.hire.isCogSender,
        isOrgAdmin: values.isOrgAdmin,
        isCogAdmin: values.isCogAdmin,
        moduleUpdates
      };
    }

    private moduleValuesChanged(
        previousOrgAdmin: boolean,
        currentOrgAdmin: boolean,
        previousValue: ModuleValues,
        currentValue: ModuleValues
    ): boolean {
        return (
            previousValue.accessLevel !== currentValue.accessLevel ||
            previousValue.vmAccess !== currentValue.vmAccess ||
            previousOrgAdmin !== currentOrgAdmin
        );
    }
}
