import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SurveyUserService } from '../../../app/api/services/survey-user.service';

@Component({
    selector: 'app-copy-surveyuser',
    templateUrl: './copy-surveyuser.component.html',
    styleUrls: ['./copy-surveyuser.component.scss']
})
export class CopySurveyuserComponent {
    public wasResponseSuccessful: boolean | undefined = undefined;

    public successfullyRestoreList: string[] = [];
    public failedToRestoreList: string[] = [];

    public displayedColumns: string[] = [
        'email',
        'firstName',
        'lastName',
        'assessmentGuid',
        'bsId',
        'company',
        'restore'
    ];

    public restoreTableDisplayedColumns: string[] = ['surveyUserGuid'];

    public copySurveyUserFom = this.fb.group({
        companyId: ['', { validators: Validators.required, updateOn: 'blur' }],
        restoreAssessments: [true]
    });

    public movePersonResponseForm = this.fb.group({
        responseOkMessage: [''],
        responseErrorMessage: ['']
    });

    public loading = false;

    public saving = false;

    constructor(
        private readonly fb: FormBuilder,
        private readonly surveyUserService: SurveyUserService
    ) {}

    get companyId(): string | undefined {
        return this.copySurveyUserFom.controls.companyId.value ?? undefined;
    }

    public handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.submit();
            event.stopPropagation();
        }
    }

    public submit(): void {
        if (this.copySurveyUserFom.invalid) {
            return;
        }

        this.loading = true;

        this.wasResponseSuccessful = undefined;
        const companyIdAsInteger =
            this.companyId !== undefined ? BigInt(this.companyId) : -1;

        this.surveyUserService
            .copySurveyUser({
                companyId: companyIdAsInteger
            })
            .subscribe({
                next: () => {
                    this.wasResponseSuccessful = true;
                    this.loading = false;
                },
                error: (err) => {
                    this.wasResponseSuccessful = false;
                    console.error(err);
                }
            });
    }

    public setTargetCompanyId(companyId: string): void {
        this.copySurveyUserFom.controls['companyId'].setValue(companyId);
    }
}
