/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteAllTeamSessions } from '../fn/design-team-session/delete-all-team-sessions';
import { DeleteAllTeamSessions$Params } from '../fn/design-team-session/delete-all-team-sessions';
import { deleteTeamSession } from '../fn/design-team-session/delete-team-session';
import { DeleteTeamSession$Params } from '../fn/design-team-session/delete-team-session';
import { getTeamSessions$Json } from '../fn/design-team-session/get-team-sessions-json';
import { GetTeamSessions$Json$Params } from '../fn/design-team-session/get-team-sessions-json';
import { getTeamSessions$Plain } from '../fn/design-team-session/get-team-sessions-plain';
import { GetTeamSessions$Plain$Params } from '../fn/design-team-session/get-team-sessions-plain';
import { StrategyPlaygroundSession } from '../models/strategy-playground-session';

@Injectable({ providedIn: 'root' })
export class DesignTeamSessionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTeamSessions()` */
  static readonly GetTeamSessionsPath = '/design/teamsession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeamSessions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeamSessions$Plain$Response(params?: GetTeamSessions$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StrategyPlaygroundSession>>> {
    return getTeamSessions$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeamSessions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeamSessions$Plain(params?: GetTeamSessions$Plain$Params, context?: HttpContext): Observable<Array<StrategyPlaygroundSession>> {
    return this.getTeamSessions$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StrategyPlaygroundSession>>): Array<StrategyPlaygroundSession> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTeamSessions$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeamSessions$Json$Response(params?: GetTeamSessions$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StrategyPlaygroundSession>>> {
    return getTeamSessions$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTeamSessions$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTeamSessions$Json(params?: GetTeamSessions$Json$Params, context?: HttpContext): Observable<Array<StrategyPlaygroundSession>> {
    return this.getTeamSessions$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StrategyPlaygroundSession>>): Array<StrategyPlaygroundSession> => r.body)
    );
  }

  /** Path part for operation `deleteTeamSession()` */
  static readonly DeleteTeamSessionPath = '/design/teamsession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTeamSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeamSession$Response(params?: DeleteTeamSession$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTeamSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTeamSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTeamSession(params?: DeleteTeamSession$Params, context?: HttpContext): Observable<void> {
    return this.deleteTeamSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAllTeamSessions()` */
  static readonly DeleteAllTeamSessionsPath = '/design/teamsession/all/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllTeamSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllTeamSessions$Response(params: DeleteAllTeamSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAllTeamSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllTeamSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllTeamSessions(params: DeleteAllTeamSessions$Params, context?: HttpContext): Observable<void> {
    return this.deleteAllTeamSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
