/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CognitiveAccessLevel {
  Unknown = 'Unknown',
  NoAccess = 'NoAccess',
  Send = 'Send',
  Admin = 'Admin'
}
